import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import {
  Collapse, IconWrapper, List, ListItem, Text, TextWrapper,
} from './styles';
import { SIDEBAR_MENU_OPTIONS } from './constants';
import { DASHBOARD } from '../../navigation/screenNames';
import UserContext from '../../contexts/UserContext';

const SidebarMenuItems = () => {
  const [colapse, setColapse] = useState({
    administrator: false,
    mantainance: false,
    credit_card: false,
    cards: false,
    referrals: false,
    payments_link: false,
    tap_to_phone: false,
    mercadeo: false,
    pos: false,
    recharge: false,
    payment: false,
    feature_flag: false,
    versions: false,
    commerce_mantainance: false,
    documentation: false,
  });

  const history = useHistory();
  const { userScreens } = useContext(UserContext);

  const handleClick = (origin) => {
    switch (origin) {
      case 'administrator':
        return setColapse({ administrator: !colapse.administrator });
      case 'mantainance':
        return setColapse({ mantainance: !colapse.mantainance });
      case 'credit_card':
        return setColapse({ credit_card: !colapse.credit_card });
      case 'cards':
        return setColapse({ cards: !colapse.cards });
      case 'referrals':
        return setColapse({ referrals: !colapse.referrals });
      case 'payments_link':
        return setColapse({ payments_link: !colapse.payments_link });
      case 'mercadeo':
        return setColapse({ mercadeo: !colapse.mercadeo });
      case 'pos':
        return setColapse({ pos: !colapse.pos });
      case 'payment':
        return setColapse({ payment: !colapse.payment });
      case 'recharge':
        return setColapse({ recharge: !colapse.recharge });
      case 'request':
        return setColapse({ request: !colapse.request });
      case 'feature_flag':
        return setColapse({ feature_flag: !colapse.feature_flag });
      case 'remittance':
        return setColapse({ remittance: !colapse.remittance });
      case 'versions':
        return setColapse({ versions: !colapse.versions });
      case 'commerce_mantainance':
        return setColapse({ commerce_mantainance: !colapse.commerce_mantainance });
      case 'documentation':
        return setColapse({ documentation: !colapse.documentation });
      default:
        return null;
    }
  };

  const validateOptionPermission = (screenName) => {
    /// Eliminar este flag al completar pantallas manejo de roles
    if (userScreens.flagIgnore) return true;
    if (screenName === `/${DASHBOARD}`) return true;
    if (userScreens?.permissions?.length > 0) {
      return userScreens?.permissions.find(
        (permission) => (screenName.includes(permission.screenName)
              && (permission.canWrite || permission.canRead)),
      );
    }
    return false;
  };

  const validateSectionPermission = (sections) => {
    /// Eliminar este flag al completar pantallas manejo de roles
    if (userScreens.flagIgnore) return sections;
    if (userScreens?.permissions?.length > 0) {
      const sectionClean = sections.filter((section) => userScreens?.permissions.find(
        (permission) => section.navigation.includes(permission.screenName)
          && (permission.canRead || permission.canWrite),
      ));
      return sectionClean || [];
    }
    return [];
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ height: 300, margin: '0 auto 16px' }}>
        <List>
          {SIDEBAR_MENU_OPTIONS.map((options) => {
            let filterSections = [];
            if (options?.sections) {
              filterSections = validateSectionPermission(options.sections || []);
            }
            const hasSubItems = options?.sections?.length > 0;
            const isColapse = colapse[options.colapse];
            const isSelected = history.location.pathname === options.navigation;

            // validate permissions optionmenu without subsections
            if (!options.sections) {
              if (!validateOptionPermission(options.navigation)) return <></>;
            }
            // validate permissions optionmenu with subsections
            if (options.sections && filterSections.length === 0) {
              return <></>;
            }

            return (
              <div key={`sidebar-${options.title}`}>
                <ListItem
                  button
                  selected={isSelected}
                  onClick={() => {
                    if (hasSubItems) handleClick(options.colapse);
                    else history.push(options.navigation);
                  }}
                >
                  <IconWrapper>
                    {options.icon}
                  </IconWrapper>
                  <TextWrapper>
                    <Text text={options.title} />
                  </TextWrapper>
                  {hasSubItems && (
                    isColapse
                      ? <ExpandLess />
                      : <ExpandMore />
                  )}
                </ListItem>
                {hasSubItems && (
                  <Collapse open={isColapse}>
                    {filterSections.map((section) => (
                      <List disablePadding key={`sidebar-subitem-${section.title}`}>
                        <ListItem
                          subSection
                          onClick={() => history.push(section.navigation)}
                          selected={history.location.pathname === section.navigation}
                        >
                          <IconWrapper>
                            {section.icon}
                          </IconWrapper>
                          <TextWrapper>
                            <Text text={section.title} />
                          </TextWrapper>
                        </ListItem>
                      </List>
                    ))}
                  </Collapse>
                )}
              </div>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default SidebarMenuItems;
