import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: '30vh',
    flexDirection: 'row',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
}));

export default useStyle;
