import { makeStyles } from '@material-ui/core/styles';
import { THEME } from '../../../theme/colors';

const useStyle = makeStyles(() => ({
  productCard: ({ selected }) => (
    {
      width: 180,
      height: 120,
      borderRadius: 12,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      backgroundColor: 'white',
      border: `2px solid ${selected ? THEME.primary : THEME.black10}`,
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    }),
  image: {
    width: '92px',
    height: '92px',
    objectFit: 'cover',
  },
  text: {
    marginTop: 1,
    textAlign: 'center',
  },
}));

export default useStyle;
