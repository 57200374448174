const paddingLeft = '40px';

export const columns = [
  {
    id: 'name',
    label: 'Nombre del documento',
    paddingLeft,
    width: 150,
    position: 1,
  },
  {
    id: 'type',
    label: 'Tipo de documento',
    paddingLeft,
    width: 150,
    position: 2,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 3,
  },
];

export const DOCUMENT_TYPES = {
  MULTIMEDIA: 'Multimedia',
  TEXT: 'Texto',
  MAP: 'Mapa',
  DOCUMENT: 'Documento',
};

export const DOCUMENTS_MOCK = [
  { id: '1', name: 'Foto del frente del negocio', type: DOCUMENT_TYPES.MULTIMEDIA },
  { id: '2', name: 'Instagram', type: DOCUMENT_TYPES.TEXT },
  { id: '3', name: 'Location', type: DOCUMENT_TYPES.MAP },
  { id: '4', name: 'Carga del contrato de alquiler', type: DOCUMENT_TYPES.DOCUMENT },
  { id: '5', name: 'Foto del frente del negocio', type: DOCUMENT_TYPES.MULTIMEDIA },
  { id: '6', name: 'Facebook', type: DOCUMENT_TYPES.TEXT },
  { id: '7', name: 'Location', type: DOCUMENT_TYPES.MAP },
  { id: '8', name: 'Carga del recibo del servicio de agua', type: DOCUMENT_TYPES.DOCUMENT },
  { id: '9', name: 'Foto del frente del negocio', type: DOCUMENT_TYPES.MULTIMEDIA },
  { id: '10', name: 'Twitter', type: DOCUMENT_TYPES.TEXT },
  { id: '11', name: 'Location', type: DOCUMENT_TYPES.MAP },
  { id: '12', name: 'Carga del recibo de electricidad', type: DOCUMENT_TYPES.DOCUMENT },
  { id: '13', name: 'Foto del frente del negocio', type: DOCUMENT_TYPES.MULTIMEDIA },
  { id: '14', name: 'Web', type: DOCUMENT_TYPES.TEXT },
  { id: '15', name: 'Location', type: DOCUMENT_TYPES.MAP },
  { id: '16', name: 'Carga del contrato de alquiler', type: DOCUMENT_TYPES.DOCUMENT },
  { id: '17', name: 'Foto del frente del negocio', type: DOCUMENT_TYPES.MULTIMEDIA },
  { id: '18', name: 'Tiktok', type: DOCUMENT_TYPES.TEXT },
  { id: '19', name: 'Location', type: DOCUMENT_TYPES.MAP },
  { id: '20', name: 'Carga del contrato de alquiler', type: DOCUMENT_TYPES.DOCUMENT },
  { id: '21', name: 'Foto del frente del negocio', type: DOCUMENT_TYPES.MULTIMEDIA },
  { id: '22', name: 'MySpace', type: DOCUMENT_TYPES.TEXT },
  { id: '23', name: 'Location', type: DOCUMENT_TYPES.MAP },
  { id: '24', name: 'Carga del contrato de alquiler', type: DOCUMENT_TYPES.DOCUMENT },
];
