import httpInstance from '../httpInstance';

const createRubro = async (dataSent) => {
  let res;
  const endpoint = '/frontlabels/v1/business/rubro';
  await httpInstance
    .post(endpoint, dataSent)
    .then((data) => {
      res = data;
    })
    .catch((err) => {
      res = err.response;
    });
  return res;
};

const createBatchRubro = async (dataSent) => {
  let res;
  const endpoint = '/frontlabels/v1/business/rubro/batch';
  await httpInstance
    .post(endpoint, dataSent)
    .then((data) => {
      res = data;
    })
    .catch((err) => {
      res = err.response;
    });
  return res;
};

export { createRubro, createBatchRubro };
