/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';

const DropdownWithFilter = ({
  label,
  className,
  options,
  disabled,
  variant,
  InputProps,
  InputLabelProps,
  placeholder,
  classNameTextField,
  value,
  onChange,
  onAddOption,
  maxCharacters = 200,
}) => {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState('');
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const displayValue = value !== undefined ? value : internalValue;
  const filteredOptions = options.filter((option) => option.label
    .toLowerCase().includes(displayValue.toLowerCase()));

  return (
    <FormControl style={className} fullWidth>
      <TextField
        ref={anchorRef}
        label={label}
        placeholder={placeholder}
        InputLabelProps={InputLabelProps}
        size="small"
        variant={variant || 'outlined'}
        className={classNameTextField}
        value={displayValue}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue.length <= maxCharacters) {
            if (onChange) {
              onChange(newValue);
            } else {
              setInternalValue(newValue);
            }
            setOpen(true);
          }
        }}
        onFocus={() => setOpen(true)}
        disabled={disabled}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {displayValue && (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (onChange) {
                      onChange('');
                    } else {
                      setInternalValue('');
                    }
                    setOpen(false);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton size="small" onClick={() => setOpen(!open)}>
                <ExpandMoreIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{ zIndex: 1300, width: anchorRef.current?.offsetWidth || '100%' }}
      >
        <ClickAwayListener
          onClickAway={(event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
              return;
            }
            setOpen(false);
          }}
        >
          <Paper elevation={3} className={classes.paper}>
            <List>
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <ListItem
                    button
                    key={option.value}
                    onClick={() => {
                      if (onChange) {
                        onChange(option.label);
                      } else {
                        setInternalValue(option.label);
                      }
                      setOpen(false);
                    }}
                  >
                    {option.label}
                  </ListItem>
                ))
              ) : (
                <>
                  <ListItem disabled>
                    No existen coincidencias en la búsqueda. ¿Quieres agregar &quot;
                    {displayValue.toUpperCase()}
                    &rdquo; como rubro nuevo?
                  </ListItem>
                  {onAddOption && (
                    <ListItem>
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.button}
                        onClick={async () => {
                          await onAddOption(displayValue);
                          setOpen(false);
                        }}
                      >
                        Agregar
                      </Button>
                    </ListItem>
                  )}
                  {value.length >= maxCharacters && (
                  <p className={classes.validationMessage}>
                    *El máximo de carácteres para un rubro es de 200
                  </p>
                  )}
                </>
              )}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </FormControl>
  );
};

export default DropdownWithFilter;
