import React from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import useStyle from './useStyle';
import { THEME } from '../../../theme/colors';

const ProductCard = ({
  image, text, onClick, selected,
}) => {
  const classes = useStyle(selected);
  return (
    <ButtonBase
      onClick={onClick}
      className={classes.productCard}
      style={{ border: `2px solid ${selected ? THEME.primary : THEME.black10}` }}
    >
      <Box
        component="img"
        src={image}
        alt={text}
        className={classes.image}
      />
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
    </ButtonBase>
  );
};

export default ProductCard;

ProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
