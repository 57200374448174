/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import { THEME } from '../../theme/colors';

export const useStyles = makeStyles(() => ({
  paper: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  button: {
    backgroundColor: THEME.primary,
    color: THEME.white,
  },
  validationMessage: {
    textAlign: 'center',
    color: THEME.firebrick,
    marginBottom: '12px',
  },
}));
