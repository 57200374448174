/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SnackbarProvider } from 'notistack';
import { CustomTable } from '../../../../components';
import useStyle from './useStyle';
import { columns } from './constants';

const DocumentsTable = ({
  enteredFilter,
  documents,
}) => {
  const classes = useStyle();
  const providerRef = useRef();
  const capitalize = (text) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : text);
  const [documentsRow, setDocumentsRow] = useState([]);
  const [documentsRowCopy, setDocumentsRowCopy] = useState([]);

  const filterInfoTable = (filter, codesMapped) => {
    const documentList = codesMapped != null ? codesMapped : documentsRowCopy;
    const filteredList = documentList.filter(
      (document) => document.name
        .toString()
        .toLowerCase()
        .includes(filter.toLowerCase())
        || document.type
          .toLowerCase()
          .includes(filter.toLowerCase()),
    );
    setDocumentsRow(filteredList);
  };

  const createActions = () => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <IconButton
        edge="end"
        aria-label="Editar"
        size="small"
        onClick={() => {}}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Editar" aria-label="edit" placement="top">
          <EditIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Eliminar"
        size="small"
        onClick={() => {}}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Eliminar" aria-label="edit" placement="top">
          <DeleteIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Preview"
        size="small"
        onClick={() => {}}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Preview" aria-label="edit" placement="top">
          <VisibilityIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const setDocuments = (documentsParam) => {
    const documentsInfo = documentsParam?.map((document) => ({
      name: capitalize(document.name),
      type: capitalize(document.type),
      active: true,
      actions: createActions(),
      id: document.id,
    }));

    setDocumentsRow(documentsInfo);
    setDocumentsRowCopy(documentsInfo);
    if (enteredFilter !== '') {
      filterInfoTable(enteredFilter, documentsInfo);
    }
  };

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  useEffect(() => {
    setDocumentsRow([]);
    setDocuments(documents);
  }, [documents]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable data={documentsRow} columns={columns} />
    </SnackbarProvider>
  );
};

export default DocumentsTable;
