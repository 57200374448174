/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './useStyle';
import newButton from '../../assets/img/iconos/newButton.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import ActionForm from './components/ActionForm/ActionForm';
import { Loading, Toaster } from '../../components';
import CsvReader from './components/CsvReader/CsvReader';
import BulkLoadPreview from './components/BulkLoadPreview/BulkLoadPreview';
import CustomDialog from './components/CustomDialog/CustomDialog';
import getAllCommercialCategoryService from '../../services/commercialCategory/getAllCommercialCategoryService';
import postBatchCommercialCategoriesService from '../../services/commercialCategory/postBatchCommercialCategoriesService';
import { getAllRubros, createBatchRubro } from '../../services';
import { errorAlert, successAlert, defaultData } from './constants';
import CommercialCategoriesTable from './components/DataTable/CommercialCategoriesTable';

const CommercialCategoryScreen = () => {
  const classes = useStyle();
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [showBulkPreview, setShowBulkPreview] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showDuplicatedMccDialog, setShowDuplicatedMccDialog] = useState(false);
  const [duplicatedMcc, setDuplicatedMcc] = useState([]);
  const [enteredFilter, setEnteredFilter] = useState('');
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [errorMessage, setErrorMessage] = useState('');
  const [comercialCategoryList, setComercialCategoryList] = useState([]);
  const [
    comercialCategoryListForCsvReader,
    setComercialCategoryListForCsvReader,
  ] = useState({});
  const [sectorsList, setSectorsList] = useState({});
  const [bulkData, setBulkData] = useState([]);
  const [popUpAction, setPopUpAction] = useState({
    open: false,
    title: '',
    data: null,
  });
  const [loading, setLoading] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);

  const fetchAllCategories = async () => {
    setLoading(true);
    const { data: commercialCategory, status } = await getAllCommercialCategoryService();
    if (status !== 200) {
      setAlert(errorAlert);
    } else {
      setComercialCategoryList(commercialCategory);
    }
    setLoading(false);
  };

  const prepareCommercialCategoriesForCsvReader = () => {
    const hashTable = {};
    comercialCategoryList.forEach((item) => {
      const mcc = item.mcc;
      hashTable[`${mcc}`] = item;
    });
    setComercialCategoryListForCsvReader(hashTable);
  };

  const fetchAllRubros = async () => {
    const { data: sectorsData, status } = await getAllRubros();
    const sectorsHashTable = {};
    if (status !== 200) {
      setAlert(errorAlert);
    } else {
      sectorsData.forEach((item) => {
        const description = item.description;
        sectorsHashTable[`${description}`] = item;
      });
      setSectorsList(sectorsHashTable);
    }
    return sectorsHashTable;
  };

  const saveHandler = async () => {
    setLoading(true);
    try {
      const missingCategories = bulkData
        .filter(
          (data) => !Object.keys(sectorsList)
            .some((key) => key.toUpperCase() === data.businessCategory.toUpperCase()),
        )
        .map((data) => ({
          id: '',
          description: data.businessCategory.toUpperCase(),
          enabled: true,
        }));
      let batchPayload = [];
      if (missingCategories.length > 0) {
        const { status } = await createBatchRubro(missingCategories);
        const allRubrosData = await fetchAllRubros();
        if (status === 201) {
          batchPayload = bulkData.map((data) => ({
            detail_name_es: data.description,
            enabled: true,
            mcc: data.mcc,
            itbis: data.itbis === 'SI',
            justBackOffice: !data.visible,
            rubro: data.businessCategory.toUpperCase(),
            rubro_id: allRubrosData[data.businessCategory.toUpperCase()]?.id,
            classification: data.classification,
          }));
        } else {
          throw new Error('Error creating business category batch');
        }
      } else {
        batchPayload = bulkData.map((data) => ({
          detail_name_es: data.description,
          enabled: true,
          mcc: data.mcc,
          itbis: data.itbis === 'SI',
          justBackOffice: !data.visible,
          rubro: data.businessCategory.toUpperCase(),
          rubro_id: sectorsList[data.businessCategory.toUpperCase()].id,
          classification: data.classification,
        }));
      }
      const { status } = await postBatchCommercialCategoriesService(batchPayload);
      if (status === 200 || status === 201) {
        setComercialCategoryList((prev) => ([
          ...prev,
          ...batchPayload,
        ]));
        setShowBulkPreview(false);
        setAlert(successAlert);
      } else {
        setAlert(errorAlert);
      }
    } catch (error) {
      setAlert(errorAlert);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCategories();
    fetchAllRubros();
  }, []);

  const createNewCommercialCategory = () => {
    setDefaultValues(defaultData);
    setPopUpAction({
      open: true,
      title: 'Crear nueva categoría',
      data: null,
    });
    setUpdateMode(false);
  };

  return (
    <Paper className={classes.paper}>
      {loading && <Loading />}
      <div className={classes.root}>
        <div>
          <TextField
            className={classes.searchTextField}
            value={enteredFilter}
            onChange={(e) => setEnteredFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={searchIcon}
                    alt="searchIcon"
                    style={{ paddingLeft: '10px' }}
                  />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            placeholder="Buscar"
          />
          <Tooltip
            title="Crear nueva categoría"
            aria-label="Crear nueva categoría"
          >
            <IconButton
              onClick={createNewCommercialCategory}
              style={{ fontSize: '20px', float: 'right' }}
              edge="end"
              size="small"
              classes={{ disabled: classes.disabled }}
            >
              <img src={newButton} alt="newButton" />
            </IconButton>
          </Tooltip>
          <CsvReader
            sectorsList={sectorsList}
            setData={setBulkData}
            setErrorMessage={setErrorMessage}
            setShowBulkPreview={setShowBulkPreview}
            setShowErrorDialog={setShowErrorDialog}
            comercialCategoryList={comercialCategoryListForCsvReader}
            setDuplicatedMcc={setDuplicatedMcc}
            setShowDuplicatedMccDialog={setShowDuplicatedMccDialog}
            onClick={prepareCommercialCategoriesForCsvReader}
          />
        </div>
        <CommercialCategoriesTable
          commercialCategories={comercialCategoryList}
          setDefaultValues={setDefaultValues}
          enteredFilter={enteredFilter}
          setPopUpAction={setPopUpAction}
          setUpdateMode={setUpdateMode}
        />
      </div>
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        sectorsList={sectorsList}
        title={popUpAction.title}
        defaultValues={defaultValues}
        getCommercialCategories={fetchAllCategories}
        updateMode={updateMode}
        fetchAllRubros={fetchAllRubros}
        onClose={() => {
          setPopUpAction({ open: false, title: '', data: null });
          fetchAllCategories();
        }}
      />
      <BulkLoadPreview
        open={showBulkPreview}
        data={bulkData}
        sectorsList={sectorsList}
        setData={setBulkData}
        duplicatedMcc={duplicatedMcc}
        setMainAlert={setAlert}
        onClose={() => setShowBulkPreview(false)}
        onSave={saveHandler}
        loading={loading}
      />
      <CustomDialog
        firstButtonAction={() => setShowErrorDialog(false)}
        isOpen={showErrorDialog}
        setIsOpen={setShowErrorDialog}
        title="Error al cargar documento"
        firstButtonLabel="Aceptar"
      >
        <p style={{ whiteSpace: 'pre-wrap' }}>{errorMessage}</p>
      </CustomDialog>
      <CustomDialog
        firstButtonAction={() => setShowBulkPreview(true)}
        isOpen={showDuplicatedMccDialog && !errorMessage}
        setIsOpen={setShowDuplicatedMccDialog}
        title="¡Categorías existentes!"
        firstButtonLabel="Continuar"
        secondButtonLabel="Detener"
        secondButtonAction={() => setShowDuplicatedMccDialog(false)}
      >
        <p>Algunas categorías que intentas agregar ya existen:</p>
        {duplicatedMcc.map((category) => (
          <p key={category.mcc}>
            {category.mcc}
            {' - '}
            {category.description}
            {' - '}
            {category.businessCategory}
          </p>
        ))}
      </CustomDialog>
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </Paper>
  );
};

export default CommercialCategoryScreen;
