/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ExpandLess } from '@material-ui/icons';

export const Text = styled.span`
  display: block;
  line-height: 1.5;
  font-size: 1rem;
  text-align: left;
  &::after {
    content: "${({ text }) => text}";
  }
`;

export const List = styled.div`
  margin: 0;
  padding: ${({ disablePadding }) => (disablePadding ? '0' : '8px 0')};
  position: relative;
  list-style: none;
`;

export const ListItem = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${({ subSection }) => (subSection ? '25px' : '16px')};
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 0;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  background-color: ${({ theme, selected }) => (selected ? theme.primary20 : 'transparent')};
  width: 100%;
  transition: background 0.5s;
  &:hover {
    background-color: ${({ theme }) => theme.black5};
  }
  &:active {
    background-color: ${({ theme }) => theme.black5};
    background-size: 100%;
    transition: background 0s;
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
      background-size: 100%;
      transition: none;
    }
  }
`;

export const TextWrapper = styled.div`
  margin: 4px 0;
  width: 100%;
  color: ${({ theme, selected }) => (selected ? theme.primary : theme.black)};
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  min-width: 48px;
  flex-shrink: 0;
  color: ${({ theme, selected }) => (selected ? theme.primary : theme.black40)};
`;

export const Collapse = styled.div`
  max-height: ${({ open }) => (open ? '100%' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-out;
  transition-duration: 204ms;
`;

export const ExpandLessStyled = styled(ExpandLess)`
  fill: ${({ selected, theme }) => (selected ? theme.primary : theme.black40)}
`;
