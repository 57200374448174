import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  searchTextField: {
    boxShadow: '0px 3px 2px #00000026',
    width: '504px',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
    marginBottom: '16px',
  },
  disabled: {
    opacity: 0.5,
  },
  searchIcon: {
    paddingLeft: '10px',
  },
}));

export default useStyle;
