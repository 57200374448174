import React, { useRef } from 'react';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileOutlined from '@material-ui/icons/AttachFileOutlined';
import { RISK_CLASIFICATION_ENG } from '../../constants';

const CsvReader = ({
  onClick,
  setData,
  setErrorMessage,
  setShowBulkPreview,
  setShowErrorDialog,
  setDuplicatedMcc,
  comercialCategoryList,
  setShowDuplicatedMccDialog,
}) => {
  const VALID_ITBIS_VALUES = ['SI', 'NO'];
  const VALID_CLASIFICACION_VALUES = ['HIGH_RISK', 'LOW_RISK', 'NO_BUSINESS_OBJECT'];
  const fileInputRef = useRef(null);

  const validateData = (resultData) => {
    const errors = [];
    const mccSet = new Set();
    const pushError = (error) => {
      if (!errors.includes(error)) errors.push(error);
    };
    resultData.forEach((row) => {
      if (
        !row.description
        || !row.mcc
        || row.itbis === undefined
        || !row.businessCategory || !row.classification
      ) {
        pushError('Todos los campos son obligatorios.');
      }
      if (row.itbis && !VALID_ITBIS_VALUES.includes(row.itbis)) {
        pushError('Valor inválido para "Retiene ITBIS". Debe ser "SI" o "NO".');
      }
      if (mccSet.has(row.mcc)) {
        pushError(`MCC duplicado (${row.mcc})`);
      } else {
        mccSet.add(row.mcc);
      }
      if (row.classification && !VALID_CLASIFICACION_VALUES.includes(row.classification)) {
        pushError('Valor inválido para "Clasificación". Debe ser "Alto riesgo", "Bajo riesgo" o "No objeto de negocio".');
      }
    });
    return errors;
  };
  const cleanData = (resultData) => resultData
    .map((row) => ({
      description: row.Descripción.trim(),
      mcc: row.MCC,
      itbis: row['Retiene ITBIS'].trim(),
      businessCategory: row.Rubro.trim(),
      classification: RISK_CLASIFICATION_ENG[row.Clasificación.trim()],
      visible: true,
    }));

  const handleData = (resultData) => {
    const cleanedData = cleanData(resultData);
    const validationErrors = validateData(cleanedData);
    if (validationErrors.length > 0) {
      setErrorMessage(`${validationErrors.join('\n')}\n Por favor, validar el archivo y volver a cargarlo.`);
      setShowErrorDialog(true);
      setData([]);
      fileInputRef.current.value = null;
    } else {
      const duplicatedMcc = cleanedData
        .filter((category) => comercialCategoryList[category.mcc] !== undefined);
      if (duplicatedMcc.length) {
        setDuplicatedMcc(duplicatedMcc);
        setShowDuplicatedMccDialog(true);
      } else {
        setShowDuplicatedMccDialog(false);
        setDuplicatedMcc([]);
        setShowBulkPreview(true);
      }
      setData(cleanedData);
      setShowErrorDialog(false);
      setErrorMessage('');
      fileInputRef.current.value = null;
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (fileExtension === 'csv') {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            handleData(results.data);
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
            setShowErrorDialog(true);
          },
        });
      } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const resultData = jsonData
            .filter((row, index) => index > 1 && row.length > 1)
            .filter((row) => row.length > 0)
            .map((row) => ({
              Descripción: row[0],
              MCC: row[1],
              'Retiene ITBIS': row[2],
              Rubro: row[3],
              Clasificación: row[4],
            }));
          handleData(resultData);
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          setShowErrorDialog(true);
        };
        reader.readAsArrayBuffer(file);
      } else {
        setErrorMessage('Formato de archivo no soportado. Por favor, suba un archivo CSV o Excel.');
        setShowErrorDialog(true);
        fileInputRef.current.value = null;
      }
    }
  };

  const handleButtonClick = () => {
    onClick();
    fileInputRef.current.click();
  };

  return (
    <>
      <Tooltip title="Carga masiva de categorías" aria-label="Carga masiva de categorías">
        <AttachFileOutlined
          onClick={handleButtonClick}
          style={{
            cursor: 'pointer',
            fontSize: '35px',
            float: 'right',
            paddingTop: '3px',
          }}
        />
      </Tooltip>
      <input
        type="file"
        accept=".csv, .xlsx, .xls"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
    </>
  );
};

export default CsvReader;

CsvReader.propTypes = {
  onClick: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  setShowBulkPreview: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setShowDuplicatedMccDialog: PropTypes.func.isRequired,
  comercialCategoryList: PropTypes.shape([]).isRequired,
  setShowErrorDialog: PropTypes.func.isRequired,
  setDuplicatedMcc: PropTypes.func.isRequired,
  sectorsList: PropTypes.shape(
    {
      [PropTypes.string]: PropTypes.shape(
        {
          id: PropTypes.string,
          description: PropTypes.string,
          enabled: PropTypes.bool,
        },
      ),
    },
  ).isRequired,
};
