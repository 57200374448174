/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyle from './useStyle';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import { Loading, Toaster } from '../../components';
import { errorAlert } from './constants';
import getAllDocuments from '../../services/documents/getAllDocuments';
import DocumentsTable from './components/DataTable/DocumentsTable';

const DocumentationScreen = () => {
  const classes = useStyle();
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [enteredFilter, setEnteredFilter] = useState('');
  const [documents, setDocuments] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchDocuments = async () => {
    setLoading(true);
    const { data: documentsData, status } = await getAllDocuments();
    if (status !== 200) {
      setAlert(errorAlert);
    } else {
      setDocuments(documentsData);
    }
    setLoading(false);
    return documentsData;
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <Paper className={classes.paper}>
      {loading && <Loading />}
      <div className={classes.root}>
        <div>
          <TextField
            className={classes.searchTextField}
            value={enteredFilter}
            onChange={(e) => setEnteredFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={searchIcon}
                    alt="searchIcon"
                    className={classes.searchIcon}
                  />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            placeholder="Buscar"
          />
        </div>
        <DocumentsTable
          documents={documents}
          enteredFilter={enteredFilter}
        />
      </div>
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </Paper>
  );
};

export default DocumentationScreen;
