/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-computed-key */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable dot-notation */
import React, { useState } from 'react';
import {
  Paper,
} from '@material-ui/core';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import ProductCard from './components/productCard';
import paymentLink from '../../assets/img/link.png';
import billetap from '../../assets/img/billetap.png';
import mpos from '../../assets/img/mpos-only.png';

const DocumentConfigByProductScreen = () => {
  const PRODUCTS = [
    { id: 'MPOS', name: 'Terminal Billet', image: mpos },
    { id: 'LINK', name: 'Link de pagos', image: paymentLink },
    { id: 'BILLETAP', name: 'BilleTap', image: billetap },
  ];
  const [productSelected, setProductSelected] = useState('');
  const classes = useStyle();
  const handleClick = (productName) => setProductSelected(productName);

  return (
    <InternalPage loading={false} error={alert.type === 'error'}>
      <Paper className={classes.paper}>
        {PRODUCTS.map((product) => (
          <ProductCard
            text={product.name}
            onClick={() => handleClick(product.id)}
            key={product.id}
            selected={productSelected === product.id}
            image={product.image}
          />
        ))}
      </Paper>
    </InternalPage>
  );
};

export default DocumentConfigByProductScreen;
